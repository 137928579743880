import { abtUserAnalysis, getUserAbtData } from '@shein-aidc/basis-abt-router'
import schttp from 'public/src/services/schttp'
import { SToast } from '@shein-aidc/sui-toast'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { stringifyQueryString } from '@shein/common-function'

const SPECIAL_FEATURE_ID = 1000719
//CV属性ID
export const CVAttrId = [161, 134, 1000557, 25, 31, 179, 40, 129, 1000586, 54, 66, 133, 73, 74, 150, 1000585, 77, 79, 207, 90, 92, 99, 101, 
  106, 168, 109, 1000558, 113, 180, 1000104, 1000437, 1000438, 1000627, 1000665, 1000667, 23, 58, 9, 47, 1000709, 217, 1000713, 1000712,
  1000670, 1000004, 1000545, 1000010, 1001120]
  

// 是否命中特殊属性
export function matchSpecialFeatures(attrID) {
  return [attrID]?.includes?.(SPECIAL_FEATURE_ID)
}

// 商详属性内容是否包含Special Features
export function contentIncludeSpecialFeatures(attrSet) {
  if (!Array.isArray(attrSet) || !attrSet.length) {
    return false
  }
  const hit = attrSet.some(item => {
    if (matchSpecialFeatures(item?.attr_id)) {
      return true
    }
    if (!Array.isArray(item) || !item.length) {
      return false
    }
    return matchSpecialFeatures(item[0]?.attr_id)
  })
  return !!hit
}

/**
 * @description 获取parentCats中所有catId
 * @param {*} parentCats 
 * @returns {string[]}
 */
export function getAllCatIdsFromParentsTree (parentCats = {}) {
  if (!parentCats?.cat_id) return [] 
  let result = [parentCats.cat_id]
  if (parentCats.children?.length) {
    const recursion = (arr) => {
      arr.forEach(item => {
        if (item && item.cat_id) {
          result.push(item.cat_id)
        }
        if (item.children?.length) {
          recursion(item.children)
        }
      })
    }
    recursion(parentCats.children)
  }
  return result
}

// 加车自动领取优惠券
// import { autoGetCouponAtAddBagNotMain } from 'public/src/pages/goods_detail_v2/utils/productDetail'
export async function autoGetCouponAtAddBagNotMain (estimatedInfo, language, needToast, cb) {
  if (!estimatedInfo) return
  if (!isLogin()) return
  if (estimatedInfo.isAb) return
  const { EstimatedPrice } = estimatedInfo
  if (!(EstimatedPrice?.p?.E_Price_Cal === 'Non_Threshold' || EstimatedPrice?.p?.E_Price_Cal === 'Have_Threshold')) return
  const getEstimatedCoupon = () => {
    if (!estimatedInfo?.optimalCouponList?.length) return
    const couponCodes = estimatedInfo?.optimalCouponList?.filter(coupon => {
      const isBindCoupon = coupon?.is_bind == 1 // 是绑定券。是否是绑定类型,0-否，1-是
      const notReceived = coupon?.bind_status != 1 // 未领取。 1已领取 2未领取
      return isBindCoupon && notReceived
    })?.map(coupon => coupon?.coupon_code) || []
    return couponCodes
  }
  let estimatedCouponCodes = getEstimatedCoupon() || []
  if (!estimatedCouponCodes.length) return
  schttp({
    method: 'POST',
    url: '/api/productInfo/bindCoupon/post',
    data: {
      coupon_codes: estimatedCouponCodes
    },
  }).then(res => {
    const failure = !res || res.code != 0 || res.info?.failureList?.find?.(item => estimatedCouponCodes.includes(item.couponCode))
    if (failure) {
      cb && cb(false) 
    } else {
      cb && cb(true)
      needToast && SToast({
        content: language.SHEIN_KEY_PC_26346 || 'We automatically help you to claim coupons',
      })
    }
    const abtest = abtUserAnalysis({ posKeys: 'EstimatedPrice' })?.sa
    daEventCenter.triggerNotice({
      daId: '1-8-6-93',
      extraData: {
        coupon_id: estimatedCouponCodes.join(`,`),
        status: failure ? 0 : 1,
        abtest
      }
    })
  })
}



export const EItemConfigType = {
  // /**
  //  * 一行一图
  //  */
  // Pic1: 'Pic1',
  /**
   * 一行二图
   */
  Pic2: 'Pic2',
}

class ProductCardService {
  static promiseQueue = []
  static Config = {
    [EItemConfigType.Pic2]: {
      inited: false,
      loading: false,
      ItemConfig: {},
      AtomFields: {},
    },
  }

  static init({ type = EItemConfigType.Pic2 } = {}) {
    // console.log('ProductCardService.init', type)
    return new Promise((resolve) => {

      const { Config } = this
      const _asyncConfig = Config[type]
      if (!_asyncConfig) { // 不存在数据
        resolve(false)
        return
      }
      if (_asyncConfig.inited) { // 已经初始化
        resolve()
        return
      }

      if (_asyncConfig.loading) {
        this.promiseQueue.push({ resolve, type })
        return
      } // 正在加载中

      _asyncConfig.loading = true

      const column = 2

      schttp({
        url: '/api/productAtom/cardConfig/get',
        method: 'get',
        params: {
          newProductCard: true, // 是否新商卡 1  0
          column, // 商卡一图几行  1 2 3
        }
      }).then(res => {
        const { cardConfig, fields } = res?.info || {}
        _asyncConfig.ItemConfig = cardConfig || {}
        _asyncConfig.AtomFields = fields || {}
        _asyncConfig.inited = true
        _asyncConfig.loading = false
        this.dealPromiseQueue(type, true)
        resolve()
      }).catch(() => {
        _asyncConfig.loading = false
        this.dealPromiseQueue(type, false)
        resolve(false)
      })
    })
  }
  static getItemConfig(type = EItemConfigType.Pic2) {
    return Object.freeze(this.Config[type]?.ItemConfig || {})
  }
  static getAtomFields(type = EItemConfigType.Pic2) {
    return Object.freeze(this.Config[type]?.AtomFields || {})
  }

  static dealPromiseQueue(type, isSuccess = true) {
    this.promiseQueue.filter(d => d.type === type).forEach(({ resolve }) => {
      resolve(isSuccess)
    })
  }
}


/**
 * 获取新商卡配置
 */
export async function getNewGoodsCardV3Config(opt = {}) {

  const [abtInfo] = await Promise.all([getUserAbtData(), ProductCardService.init()])
  const {
    DetailStarReview,
    detailKeyAttribute,
    listrankingTag,
    carddiscountLabel,
  } = abtInfo
  const { isPaidUser, type = EItemConfigType.Pic2 } = opt

  const addRankingLabelToSwiper = listrankingTag?.p?.rankingTag === 'on'
  const _showDiscountLabel = carddiscountLabel?.p?.carddiscount !== 'off'

  const _asyncConfig = ProductCardService.getItemConfig(type)
  const itemConfig = {
    ..._asyncConfig,
    _showDiscountLabel,
    showDiscountLabelBeforeGoodsName: false, // 折扣标签优先级低于标题前的其他标签
    showDiscountLabelAfterPrice: _showDiscountLabel, // 价格后展示折扣标签
    showDiscountLabelBeforeGoodsNameHighPriority: false, // 折扣标签优先级高与标题前的其他标签
    showStarComment: ['B', 'star'].includes(DetailStarReview?.p?.DetailStar), // 卖点信息-星级评论数量 filed=starComment 
    showDecisionAttr: detailKeyAttribute?.p?.DetailKeyAttribute === 'show', // 卖点-决策属性 filed = enableDecisionAttr 
    showQuickShip: true,

    showEstimatedPriceOnSale: true, // 新到手价样式（到手价-占用售价坑位）
    showSheinClubDiscountValue: false, // 不展示大号付费会员折扣标签
    showSheinClubDiscountValueAfterPrice: false, // 不展示小号付费会员折扣标签
    showSheinClubLabelInServicesLabel: false, // 付费会员标签展示在服务标签里面
    showSheinClubPriceOnSale: !!isPaidUser, // 是付费会员，就将价格展示为付费会员价格
    showSheinClubNewLabel: !!isPaidUser, // 展示新的付费会员文字标签

    showBestDealLabel: true,
    addRankingLabelToSwiper,

    useHoverEffect: false, // 是否使用hover效果
  }

  const res = {
    _isPaidUser: isPaidUser, // 是否为付费会员
    showTitle: true,
    showAddBagBtn: true,
    ...itemConfig
  }

  // console.log('getNewGoodsCardV3Config', type, res, _asyncConfig)
  return res
}


/**
 * 获取商详推荐所需的abt、原子数据
 */
export async function getDetailRecommendCardV3ReqAbtInfoCCCParams({ newProductCard = true, isPaidUser }) {
  const abtInfo = await getUserAbtData()
  const displayWindowAbtBranch = abtInfo?.['detailgoodsCard']?.param?.detailGoods || ''
  const listflashSale = abtInfo?.['listflashSale']?.param?.listflashSale == 'flashSale'
  const starReview = abtInfo?.['DetailStarReview']?.param?.DetailStar || ''
  const listQuickShip = abtInfo?.['listquickship']?.p?.listquickship || '' // QuickShip标签优化
  const listquickKey = abtInfo?.['listquickshipKey']?.p?.listquickKey || '' // QuickShip标签优化

  return {
    newPreHandle: true,
    isPaid: newProductCard && isPaidUser && 1 || 0, // 请求付费会员原子
    abtInfoV3: {
      newProductCard,
      starReview,
      listflashSale,
      listQuickShip,
      listquickKey,
    },
    // 原子请求ccc的参数
    atomCccParams: {
      displayWindowAbtBranch,
    },
  }
}

/**
 * 获取商卡依赖的原子
 */
export async function getDetailRecommendAtomFields({ newProductCard = true, type = EItemConfigType.Pic2 } = {}) {
  const [abtInfo] = await Promise.all([getUserAbtData(), ProductCardService.init()])
  const { localMall, listwithCoupon } = abtInfo

  const _asyncConfig = ProductCardService.getAtomFields(type)
  const fields = {
    ..._asyncConfig,
    realStock: newProductCard && localMall?.p?.localMall === 'show',
    maskLayer: true, // 商品主图蒙层接入CCC配置
    ...(listwithCoupon?.p?.listwithCoupon === 'off' ? {
      estimatedPrice: false,
    } : {}),
  }
  // console.log('getDetailRecommendAtomFields', type, fields, _asyncConfig)
  return fields
}


export const getNotEstimatedPriceConfig = (itemConfig = {}, useAbPrice = true) => {
  return {
    ...itemConfig,
    showEstimateTag: false, // 无到手价
    showEstimatedPriceReachThreshold: false, // 无到手价
    showEstimatedPriceOnSale: false, // 无到手价
    showEstimatedPrice: false, // 无到手价
    showNewStyleEstimated: false, // 无到手价新样式
    ...(useAbPrice ? {
      showABPriceOnSale: true, // 显示AB测试价格
      showABPriceTag: true, // 显示AB测试价格
    } : {})
  }
}


/**
 * 榜单跳转链接
 */
export function getRankingLink(options) {
  if (typeof window === 'undefined') return ''

  const { src_identifier, contentCarrierId, goods_id, carrierSubType, tab_page_id, title } = options || {}
  if (!isHasRankingLink({ title, carrierSubType })) return ''

  const subTypeStr = String(carrierSubType)
  const queryObj = {
    page_title: title,
    contentCarrierId,
    carrierSubType: subTypeStr,
    src_module: 'recommend', // 商详为 recommend·
    src_identifier,
    src_tab_page_id: tab_page_id,
    goods_id: goods_id,
    page_from: window?.getSaPageInfo?.page_name,
  }

  return `/ranking_list?${stringifyQueryString({
    queryObj,
  })}`
}

/**
 * 是否存在榜单跳转链接(UI层有个箭头展示)
 */
export function isHasRankingLink({ title, carrierSubType } = {}) {
  if (!title) return false
  const subTypeStr = String(carrierSubType)
  if (['15', '16'].includes(subTypeStr)) return true

  return false
}
