<template>
  <ProductCardPriceSimpleSuggested
    v-if="useSuggestedCom"
    v-bind="$attrs"
    :goods-info="goodsInfo"
    :config="priceCardConfig"
  />
  <ProductCardPriceSimple
    v-else
    v-bind="$attrs"
    :goods-info="goodsInfo"
    :config="priceCardConfig"
  />
</template>
<script>
import ProductCardPriceSimple from 'public/src/pages/components/productItemV3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import ProductCardPriceSimpleSuggested from 'public/src/pages/components/productItemV3/components/ProductCardPrice/ProductCardPriceSimpleSuggested.vue'
import { isDeSuggestedSalePrice, isNomalSuggestedSalePrice, isSpecialSuggestedSalePrice } from 'public/src/pages/components/productItemV3/utils/priceDisplayTheResults.js'

/**
 * 组件会在快车用，不能使用商详的vuex
 */
export default {
  components: {
    ProductCardPriceSimple,
    ProductCardPriceSimpleSuggested
  },
  props: {
    goodsInfo: {
      type: Object,
      default: () => { }
    },
    config: {
      type: Object,
      default: () => { }
    },
    hideSuggestedTipPopover: {
      type: Boolean,
      default: false
    },
    showFrDeRrpPrice: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isRRP() {
      return this.goodsInfo?.srpPriceInfo?.srpPrice?.amount > 0
    },
    useSuggestedCom() {
      if (this.complianceModeDe) {
        return this.isRRP
      }
      if (!this.complianceMode || this.complianceTipsMode || this.complianceModeDe) return false
      return true
    },
    complianceType() {
      return this.goodsInfo?.pretreatInfo?.suggestedSalePriceConfigInfo?.type
    },
    complianceMode() {
      return isNomalSuggestedSalePrice(this.complianceType)
    },
    complianceTipsMode() {
      return isSpecialSuggestedSalePrice(this.complianceType)
    },
    complianceModeDe() {
      return isDeSuggestedSalePrice(this.complianceType)
    },
    priceCardConfig() {
      const { config, isRRP, complianceTipsMode, complianceModeDe, hideSuggestedTipPopover, showFrDeRrpPrice } = this

      const useBlackColorPrice = complianceTipsMode || complianceModeDe

      const _baseConfig = {
        camelCasePriceMaxSize: 17,
        camelCasePriceMinSize: 11,
        direction: 'column',
      }
      const suggestedConfig = {  // 展示法、德RRP价格
        showFrDeRrpPrice: showFrDeRrpPrice,
        disabledSuggestedTipPopover: hideSuggestedTipPopover
      }
      const _blackConfig = useBlackColorPrice ? {
        priceColor: '#000',
        hidePriceBottomLabel: true, // 隐藏到手价tips
      } : {}
      if(showFrDeRrpPrice) {
        suggestedConfig.style = {
          frDeRrpPricePosition: 'bottom'
        }
        if(complianceModeDe && isRRP) { // 价格颜色跟随列表
          _blackConfig.priceColor = ''
        }
      }
      return {
        ..._baseConfig,
        ...suggestedConfig,
        ..._blackConfig,
        ...(config || {}),
      }
    }
  }
}
</script>
